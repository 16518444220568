import { Flex, Box, Button , Tooltip} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import openSocket from "socket.io-client";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useToast, Center, VStack,Heading } from "@chakra-ui/react";
import { BiSearch } from "react-icons/bi";
// user Imports
import NavBar from "./NavBar";
import Feed from "./Feed";
import CommandBar from "./CommandBar";
import ChatScreen from "../chat/ChatScreen";
import ClusterLand from "../cluster/ClusterLand";
import CreatePostComp from "../../Components/CreatePostComp";
import UserList from "../connections/friends/UserList";
import ProfileComponent from "../profile/ProfileComponent";
import Header from "../cluster/Header";
import { colors } from "../../globalStyles/colors";
import MenuButton from "../../Components/MenuButton";
import LogoSpinner from "../../animations/LogoSpinner";
import GeneralInputField from "../../Components/GeneralInputField";
import { MdMessage } from "react-icons/md";

export default function Landing() {
  const navigate = useNavigate();
  const [socket, setSocket] = useState(null);
  const toast = useToast()
  const [chatScreenData, setChatScreenData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  
  const[showCreatePost, setShowCreatePost] = useState(false);
  const [showUserProfile, setShowUserProfile] = useState(null)
  
  
  const [cookies, setCookie, removeCookie] = useCookies(['payload', 'essential']);
  const location = useLocation()

  const email = location.state?.email || "E"



  console.log(email + " in Landing")

  const fetchUser = () =>{
    
  }

  const updateChatScreenData = (data) => {
    setChatScreenData(data);
    console.log(data);
  };

  const updateShowCreatePost = showPost => {
    setShowCreatePost(showPost)
  }

  const updateShowUserProfile = user => {
    setShowUserProfile(user)
  }

  useEffect(() => {
    setUserDetails(location.state?.userDetails)
    const fetchUserData = async() => {
      try{
        const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/user/details`, { params : {email : email}} ).then(
          res => {
            toast({
              title: "user details fetched",
              description: res.data.message,
              status: "success", // You can use any status like "success", "error", "warning", or "info"
              duration: 5000,
              isClosable: true,
              variant: "solid", // Use "solid" for a solid background
              // Add your custom background color
              // Example: backgroundColor: "your-color-hex-code"
              // backgroundColor: "your-color-hex-code",
              
              colorScheme:'yellow',
            });
            return res;
          }
        ).catch(err => {
          toast({
            title: "Unable to fetch user",
            description: err.data.message,
            status: "error", // You can use any status like "success", "error", "warning", or "info"
            duration: 5000,
            isClosable: true,
            variant: "solid", // Use "solid" for a solid background
            // Add your custom background color
            // Example: backgroundColor: "your-color-hex-code"
            // backgroundColor: "your-color-hex-code",
            
            colorScheme:'red',
          });
        })
  
        console.log(response.data)
        if(response.status === 200)
          setUserDetails(response.data.data)
      }catch(e){

      }

    }
    fetchUserData()
  }, [])

  useEffect(() => {

    const io = openSocket(`${process.env.REACT_APP_SERVER_BASE_URL}`);
    console.log("Socket Object:", io);

    io.on("connect", () => {
      console.log("Connected to the server");
      setSocket(io);
      setIsLoading(false); // Set loading to false once connected
    });

    io.on("disconnect", () => {
      console.log("Disconnected from the server");
    });

    io.on('message', message => {
      console.log(chatScreenData)
      
       if (chatScreenData && chatScreenData.userName != message.sender && userDetails && message.scope != 'cluster' && message.sender != userDetails?.userName ){
        
        toast({
      icon: <Box bgColor='white' display='flex' width='20px' height='20px' borderRadius='100%' alignItems='center' justifyContent='center'><MdMessage alignmentBaseline="center"  color="orange"/></Box>,
      position:'top-right', 
          title: message.sender,
          description: 'new message',
          duration: 3000,
          isClosable: true,
          variant: "solid", 
          colorScheme:'orange',
        });
      }else if(userDetails && message.scope != 'cluster' && message.sender != userDetails?.userName){
        
        toast({
      icon: <Box bgColor='white' display='flex' width='20px' height='20px' borderRadius='100%' alignItems='center' justifyContent='center'><MdMessage alignmentBaseline="center"  color="orange"/></Box>,
      position:'top-right', 
          title: message.sender,
          description: 'new message',
          duration: 3000,
          isClosable: true,
          variant: "solid", 
          colorScheme:'orange',
        });
      }
    })

    return () => {
      // Clean up the socket connection if needed
      io.disconnect();
    };
  }, [userDetails, chatScreenData]);

  // socket.on('message', message => {
  //   receiveMessage(message)
  // })

  const handleBetaClick = () => {
    navigate('/about')
  }

  if(isLoading) 
    return (
      <Box height='100vh'  display='flex' width='100vw' bgColor={colors.bgDark} alignItems='center' justifyContent='center'>
          <LogoSpinner width='100px' />
      </Box>
    )

  return (
    <>
        <Flex
          // justifyContent="space-around"
          flexDirection="row"
          flexWrap="nowrap"
          minHeight='100vh'
          height="100%"
          width="100%"
          bgColor="black"
          borderColor="rgb(229,224,177,0.7)"
          border="solid"
          borderWidth="0 2px 0 0"
          justifyContent='center'
          // p="10px"
        >
          <Flex width="20%" flexDirection="column"  position="fixed" left="20" top="0" zIndex="0" height="100vh"  >
           <CommandBar socket={socket} updateChatScreenData={updateChatScreenData} userDetails={userDetails} updateShowCreatePost={updateShowCreatePost} updateShowUserProfile={updateShowUserProfile} />
          </Flex>
          {/* <Box ml="10px"></Box> */}
          <Flex flex="2" ml="25.2%" width="50%"  >
          {(!showCreatePost) && <Feed socket={socket} scope='G' userDetails={userDetails} />}
          {showCreatePost && <CreatePostComp userDetails={userDetails} updateShowCreatePost={updateShowCreatePost} scope='G'/>}
          </Flex>
          {chatScreenData == null ? <Flex position="fixed" top="0" right="5" zIndex="1" mr={12} width="22%" justifyContent="flex-end" flexDirection="column-reverse">
                        <Center mt={10}>
                        
                        <VStack spacing={2}>
                        <Tooltip label="Click to know more!" bgColor="black" color={colors.primary} aria-describedby="button-description">
                          <Button variant='ghost' color='white' borderWidth='2px' borderColor={colors.primary} _hover={{bgColor:"black" }} onClick={handleBetaClick} >Beta 1.0.0</Button>
                        </Tooltip>
                        </VStack>
                        </Center> 
                        
           
           <UserList userDetails={userDetails} updateShowUserProfile={updateShowUserProfile} /> 
            <Box mt={5}/>
           <GeneralInputField icon={<BiSearch color='whitesmoke' />} height='45px' placeholder="Search a friend, cluster, trending topics" width='100%'/> 
           <Box mt={2}/> 
           
          </Flex>: null}
          <Flex position="fixed" top="0" right="0" zIndex="1"  mr={10} width="25%" justifyContent="flex-end">
          {socket ? (
            
            
            <>
            <ChatScreen type='P' size='md' updateChatScreenData={updateChatScreenData} recepient={chatScreenData} socket={socket} localUser={userDetails} /> 
            </>): null}
            </Flex>
            {showUserProfile != null ? <ProfileComponent updateShowUserProfile={updateShowUserProfile} user={userDetails} name={showUserProfile.userName} type='P' /> : null}
            <Box mr={10}/>
            
        </Flex>
    </>
  );
}
